import React from 'react'

import Layout from '../components/Layout'
import Home from '../components/Home'

export default ({ location, pageContext }) => (
  <Layout
    pageTitle={pageContext.pageTitle}
    location={location}
    locale={pageContext.locale}
  >
    <Home locale={pageContext.locale} />
  </Layout>
)
