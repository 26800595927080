import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

/* Images */
import background_1 from '../../img/home_background_1.jpg'
import background_2 from '../../img/home_background_2.jpg'
import o_nas from '../../img/home_o_nas.jpg'
import spoleczna_odp from '../../img/home_spoleczna_odp.jpg'
import nasze_uslugi from '../../img/home_nasze_uslugi.jpg'
import galeria_1 from '../../img/home_galeria_1.jpg'
import galeria_2 from '../../img/home_galeria_2.jpg'
import galeria_3 from '../../img/home_galeria_3.jpg'
import galeria_4 from '../../img/home_galeria_4.jpg'
import galeria_5 from '../../img/home_galeria_5.jpg'

/* Texts */
import texts from '../../constants/texts'
import paths from '../../constants/paths'

export default ({ locale }) => {
  return (
    <div className='main-container'>
      <section
        className='cover cover-features imagebg height-80 padding'
        data-overlay='4'
      >
        <div
          className='background-image-holder'
          style={{ background: `url(${background_1})`, opacity: 1 }}
        >
          <img alt='background' src={background_1} />
        </div>
        <div className='container pos-vertical-center'>
          <div className='row'>
            <div className='col-sm-9 col-md-7'>
              <h1>
                <b>{texts[locale].home.section_1.title}</b>
              </h1>
              <p className='lead'>{texts[locale].home.section_1.paragraph}</p>
              <AniLink
                duration={1}
                cover
                direction='up'
                bg='#230d54'
                to={`${paths[locale]['/about/'].path}#${
                  texts[locale].links.contact
                }`}
                className='btn btn--primary type--uppercase'
              >
                <span className='btn__text'>
                  {texts[locale].links.contact_us}
                </span>
              </AniLink>
            </div>
          </div>
        </div>
      </section>
      <section className='switchable feature-large space--md bg--primary padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6 col-xs-12'>
              <div className='video-cover border--round box-shadow-wide'>
                <div
                  className='background-image-holder'
                  style={{
                    background: `url(${o_nas})`,
                    opacity: 1
                  }}
                >
                  <img alt='image' src={o_nas} />
                </div>
                <iframe allowFullScreen='allowfullscreen' />
              </div>
            </div>
            <div className='col-sm-6 col-md-5'>
              <div className='switchable__text'>
                <h2>
                  <b>{texts[locale].home.section_2.title}</b>
                </h2>
                <p className='lead'>{texts[locale].home.section_2.paragraph}</p>
              </div>
            </div>
            <AniLink
              duration={1}
              cover
              direction='up'
              bg='#230d54'
              to={paths[locale]['/about/'].path}
              className='btn btn--primary type--uppercase'
              style={{ margin: '25px 5px' }}
            >
              <span className='btn__text'>{texts[locale].links.meet_us}</span>
            </AniLink>
          </div>
        </div>
      </section>
      <section className='imagebg space--sm padding' data-overlay='4'>
        <div
          className='background-image-holder'
          style={{
            background: `url(${background_2})`,
            opacity: 1
          }}
        >
          <img alt='background' src={background_2} />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='cta cta--horizontal text-center-xs'>
              <div className='col-sm-4'>
                <h4>
                  <b>{texts[locale].home.section_3.title}</b>
                </h4>
              </div>
              <div className='col-sm-4'>
                <p className='lead'>{texts[locale].home.section_3.paragraph}</p>
              </div>
              <div className='text-right text-center-xs col-sm-2'>
                <AniLink
                  duration={1}
                  cover
                  direction='up'
                  bg='#230d54'
                  to={`${paths[locale]['/about/'].path}#${
                    texts[locale].links.contact
                  }`}
                  className='btn btn--primary type--uppercase'
                >
                  <span className='btn__text'>
                    {texts[locale].links.contact_us}
                  </span>
                </AniLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='feature-large feature-large-2 bg--dark padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4 col-md-3'>
              <h3>
                <b>{texts[locale].home.section_4.title}</b>
              </h3>
              <p className='lead'>{texts[locale].home.section_4.subtitle}</p>
              <AniLink
                duration={1}
                cover
                direction='up'
                bg='#230d54'
                to={paths[locale]['/TMN/'].path}
                className='btn btn--sm type--uppercase'
                style={{ margin: '25px 5px' }}
              >
                <span className='btn__text text-white'>
                  {texts[locale].home.section_4.button_1}
                </span>
              </AniLink>
              <p className='lead'>
                <br />
                {texts[locale].home.section_4.paragraph_1}
                <br />
              </p>
              <AniLink
                duration={1}
                cover
                direction='up'
                bg='#230d54'
                to={paths[locale]['/TCP/'].path}
                className='btn btn--sm type--uppercase'
                style={{ margin: '25px 5px' }}
              >
                <span className='btn__text text-white'>
                  {texts[locale].home.section_4.button_2}
                </span>
              </AniLink>
            </div>
            <div className='col-sm-4 col-md-4 col-md-offset-1'>
              <img
                alt='Image'
                className='border--round box-shadow-wide'
                src={nasze_uslugi}
              />
            </div>
            <div className='col-sm-4 col-md-2 col-md-offset-1'>
              <hr className='short' />
              <p>{texts[locale].home.section_4.paragraph_2}</p>
            </div>
          </div>
        </div>
      </section>
      <section className='text-center bg--primary space--sm padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-10 col-md-8'>
              <h1>
                <b>{texts[locale].home.section_5.title}</b>
              </h1>
              <p className='lead'>
                {texts[locale].home.section_5.paragraph_1} <br />
                {texts[locale].home.section_5.paragraph_2}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className='imagebg space--sm text-justify padding'
        data-overlay='7'
      >
        <div
          className='background-image-holder'
          style={{
            background: `url(${spoleczna_odp})`,
            opacity: 1
          }}
        >
          <img alt='background' src={spoleczna_odp} />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='feature feature-3 boxed boxed--lg boxed--border'>
                <i className='icon icon-Business-Mens icon--lg' />
                <h4>{texts[locale].home.section_5.boxes.box_1.title}</h4>
                <p>{texts[locale].home.section_5.boxes.box_1.paragraph}</p>
                {/* <a href='#'>{texts[locale].links.more}</a> */}
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='feature feature-3 boxed boxed--lg boxed--border'>
                <i className='icon icon-Tree-5 icon--lg' />
                <h4>{texts[locale].home.section_5.boxes.box_2.title}</h4>
                <p>{texts[locale].home.section_5.boxes.box_2.paragraph}</p>
                {/* <a href='#'>{texts[locale].links.more}</a> */}
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='feature feature-3 boxed boxed--lg boxed--border'>
                <i className='icon icon-Add-UserStar icon--lg' />
                <h4>{texts[locale].home.section_5.boxes.box_3.title}</h4>
                <p>{texts[locale].home.section_5.boxes.box_3.paragraph}</p>
                {/* <a href='#'>{texts[locale].links.more}</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='unpad bg--dark padding'>
        <div className='masonry masonry--gapless'>
          <div className='masonry__container masonry--active'>
            <div
              className='masonry__item col-md-4 col-sm-6 col-xs-12 filter-digital'
              data-masonry-filter='Digital'
            >
              <div className='project-thumb hover-element height-50'>
                <div className='hover-element__initial'>
                  <div
                    className='background-image-holder'
                    style={{
                      background: `url(${galeria_1})`,
                      opacity: 1
                    }}
                  >
                    <img alt='background' src={galeria_1} />
                  </div>
                </div>
                <div className='hover-element__reveal' data-overlay='2'>
                  <div className='project-thumb__title'>
                    <span />
                  </div>
                </div>
              </div>
            </div>
            <div
              className='masonry__item col-md-4 col-sm-6 col-xs-12 filter-digital'
              data-masonry-filter='Digital'
            >
              <div className='project-thumb hover-element height-50'>
                <div className='hover-element__initial'>
                  <div
                    className='background-image-holder'
                    style={{
                      background: `url(${galeria_2})`,
                      opacity: 1
                    }}
                  >
                    <img alt='background' src={galeria_2} />
                  </div>
                </div>
                <div className='hover-element__reveal' data-overlay='2'>
                  <div className='project-thumb__title'>
                    <span />
                  </div>
                </div>
              </div>
            </div>
            <div
              className='masonry__item col-md-4 col-sm-6 col-xs-12 filter-digital'
              data-masonry-filter='Digital'
            >
              <div className='project-thumb hover-element height-50'>
                <div className='hover-element__initial'>
                  <div
                    className='background-image-holder'
                    style={{
                      background: `url(${galeria_3})`,
                      opacity: 1
                    }}
                  >
                    <img alt='background' src={galeria_3} />
                  </div>
                </div>
                <div className='hover-element__reveal' data-overlay='2'>
                  <div className='project-thumb__title'>
                    <span />
                  </div>
                </div>
              </div>
            </div>
            <div
              className='masonry__item col-md-4 col-sm-6 col-xs-12 filter-digital'
              data-masonry-filter='Digital'
            >
              <div className='project-thumb hover-element height-50'>
                <div className='hover-element__initial'>
                  <div
                    className='background-image-holder'
                    style={{
                      background: `url(${galeria_4})`,
                      opacity: 1
                    }}
                  >
                    <img alt='background' src={galeria_4} />
                  </div>
                </div>
                <div className='hover-element__reveal' data-overlay='2'>
                  <div className='project-thumb__title'>
                    <span />
                  </div>
                </div>
              </div>
            </div>
            <div
              className='masonry__item col-md-8 col-sm-6 col-xs-12 filter-digital'
              data-masonry-filter='Digital'
            >
              <div className='project-thumb hover-element height-50'>
                <div className='hover-element__initial'>
                  <div
                    className='background-image-holder'
                    style={{
                      background: `url(${galeria_5})`,
                      opacity: 1
                    }}
                  >
                    <img alt='background' src={galeria_5} />
                  </div>
                </div>
                <div className='hover-element__reveal' data-overlay='2'>
                  <div className='project-thumb__title'>
                    <span>
                      <br />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='imagebg space--sm padding' data-overlay='4'>
        <div
          className='background-image-holder'
          style={{
            background: `url(${background_2})`,
            opacity: 1
          }}
        >
          <img alt='background' src={background_2} />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='cta cta--horizontal text-center-xs'>
              <div className='col-sm-4'>
                <h4>
                  <b>{texts[locale].home.section_3.title}</b>
                </h4>
              </div>
              <div className='col-sm-4'>
                <p className='lead'>{texts[locale].home.section_3.paragraph}</p>
              </div>
              <div className='text-right text-center-xs col-sm-2'>
                <AniLink
                  duration={1}
                  cover
                  direction='up'
                  bg='#230d54'
                  to={`${paths[locale]['/about/'].path}#${
                    texts[locale].links.contact
                  }`}
                  className='btn btn--primary type--uppercase'
                >
                  <span className='btn__text'>
                    {texts[locale].links.contact_us}
                  </span>
                </AniLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
